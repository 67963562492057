import { Button, Col, Icon, Popover, Row } from "antd";
import { ColumnProps } from "antd/lib/table/interface";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import imgOptions from "../../assets/icon_3_dots.svg";
import { itemDetailPath } from "../../config/paths";
import { Item } from "../../generated/axios";
import TwoLinesPopover from "../../shared/components/TwoLinesPopover/TwoLinesPopover";
import { ExtendedItem } from "../../shared/store/project/types";
import { QuoteInputExt } from "../../shared/store/quoteModal/types";
import { ModalFormMetaData } from "../../shared/store/ui/types";
import { CapabilityCheck, capabilityMap } from "../capability";
import { pricesAreVisible, ShowIfCondition } from "../capability/lib";
import { roundWithTwoDecimals } from "../configurator/components/ItemDetails/Details";
import CustomerReference from "./CustomerReference";
import Position from "./Position";
import Quantity from "./Quantity";
import QuotePresentCell from "./QuotePresentCell";
import Version from "./Version";

const stringSorter =
  (fieldName: string) => (a: ExtendedItem, b: ExtendedItem) => {
    const a1 = a?.[fieldName] ?? "";
    const b1 = b?.[fieldName] ?? "";
    return a1.localeCompare(b1, "en");
  };

export const numericSorter =
  (fieldName: string) => (a: ExtendedItem, b: ExtendedItem) =>
    typeof a?.[fieldName] === "string" && typeof b?.[fieldName] === "string"
      ? a[fieldName].localeCompare(b[fieldName], "en", { numeric: true })
      : typeof a?.[fieldName] === "string"
      ? -1
      : typeof b?.[fieldName] === "string"
      ? 1
      : -1;

interface ExtendedItemColumn
  extends ColumnProps<ExtendedItem>,
    ShowIfCondition {}

export const projectDetailsColumns = (
  createButton: (
    action: string,
    label: React.ReactElement,
    item: Item
  ) => React.JSX.Element,
  shareItem: (itemId?: number) => void,
  openModalWithForm: (formType: string, metaData: ModalFormMetaData) => void
): ExtendedItemColumn[] => {
  return [
    {
      title: () => <FormattedMessage id="position" defaultMessage="POSITION" />,
      key: "position",
      render: (item: ExtendedItem) => <Position item={item} />,
      sorter: numericSorter("position"),
      defaultSortOrder: "ascend",
      width: 150,
    },
    {
      title: () => (
        <FormattedMessage
          id="customerReference"
          defaultMessage="REF. CUSTOMER"
        />
      ),
      key: "customerReference",
      render: (item: ExtendedItem) => <CustomerReference item={item} />,
      sorter: stringSorter("customerReference"),
      defaultSortOrder: "ascend",
      width: 150,
    },
    {
      title: () => <FormattedMessage id="name" defaultMessage="NAME" />,
      key: "label",
      render: (item: ExtendedItem) => {
        return (
          item?.label && (
            <>
              <Link
                to={itemDetailPath(item.id, item.hasResultsTable)}
                className="summary__item-title"
              >
                {item.label}
              </Link>
              <br />
              <div
                className="summary__item-unit-description"
                dangerouslySetInnerHTML={{ __html: item.unitDescription ?? "" }}
              />
            </>
          )
        );
      },
      sorter: stringSorter("label"),
    },
    {
      title: "",
      key: "cpq",
      className: "cpq",
      render: (item: Item) => (
        <span className={"cpq"}>{item.cpq ? "CPQ" : "-"}</span>
      ),
      align: "center",
      width: 70,
    },
    {
      title: "",
      key: "note",
      className: "note",
      render: (item: ExtendedItem) =>
        item.note ? <TwoLinesPopover text={item.note} /> : null,
      width: 180,
    },
    {
      title: "",
      key: "price",
      className: "price",
      align: "right",
      render: (item: ExtendedItem) =>
        item?.price ? (
          <>&euro;&nbsp;{roundWithTwoDecimals(item.price)}</>
        ) : (
          <></>
        ),
      showIf: pricesAreVisible,
    },
    {
      title: "",
      key: "quote",
      render: (item: ExtendedItem) => <QuotePresentCell cpq={item.cpq} />,
      width: 40,
    },
    {
      title: "",
      key: "iconNote",
      render: (item: ExtendedItem) => {
        return (
          <div>
            {item.shared && (
              <>
                <Icon
                  className="icon--blue"
                  type="share-alt"
                  theme="outlined"
                />

                <Popover
                  overlayClassName="popover"
                  placement={"bottomRight"}
                  content={
                    <Row>
                      <Col style={{ textAlign: "left" }}>
                        <Button
                          size="default"
                          type="default"
                          onClick={() =>
                            openModalWithForm("importItem", { item })
                          }
                        >
                          <FormattedMessage
                            id="import shared item"
                            defaultMessage="Import Shared Item"
                          />
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <ReactSVG
                    src={imgOptions}
                    className="icon__content icon__content--32 icon--blue"
                    data-test="share-item-icon"
                  />
                </Popover>
              </>
            )}

            {/* Duplicate/Delete items always visibile */}
            {/* do not remove the <div> wrapper due to css purpose */}
            <div>
              <CapabilityCheck showIfHasNot={capabilityMap.PROJECT_READONLY}>
                <Popover
                  overlayClassName="popover"
                  placement={"bottomRight"}
                  content={
                    <>
                      {createButton(
                        "duplicate",
                        <FormattedMessage
                          id="duplicate item"
                          defaultMessage="Duplicate Item"
                        />,
                        item
                      )}
                      {createButton(
                        "delete",
                        <FormattedMessage
                          id="delete item"
                          defaultMessage="Delete Item"
                        />,
                        item
                      )}
                      <CapabilityCheck
                        showIfHas={capabilityMap.USER_SHARE_ITEM}
                      >
                        <Button
                          size="default"
                          type="default"
                          onClick={() => shareItem(item.id)}
                          data-test="share-item"
                        >
                          <FormattedMessage
                            id="share item"
                            defaultMessage="Share Item"
                          />
                        </Button>
                      </CapabilityCheck>
                    </>
                  }
                >
                  <ReactSVG
                    src={imgOptions}
                    className="icon__content icon__content--32 icon--blue"
                    data-test="share-item-icon"
                  />
                </Popover>
              </CapabilityCheck>
            </div>
          </div>
        );
      },
      className: "icons",
    },
  ];
};

export const columnsModal: ColumnProps<ExtendedItem>[] = [
  {
    title: () => (
      <FormattedMessage
        id="modalPreviewTS.position"
        defaultMessage="POSITION"
      />
    ),
    key: "position",
    render: (item: ExtendedItem) => <Position item={item} />,
    sorter: numericSorter("position"),
    // sortDirections: ["ascend"],
    defaultSortOrder: "ascend",
    width: 150,
  },
  {
    title: () => (
      <FormattedMessage
        id="modalPreviewTS.customerReference"
        defaultMessage="RIF.CLIENTE"
      />
    ),
    key: "customerReference",
    render: (item: ExtendedItem) => <CustomerReference item={item} />,
    sorter: stringSorter("customerReference"),
    // sortDirections: ["ascend"],
    defaultSortOrder: "ascend",
    width: 150,
  },
  {
    title: () => (
      <FormattedMessage id="modalPreviewTS.name" defaultMessage="NAME" />
    ),
    key: "unitDescription",
    render: (item: ExtendedItem) => (
      <>
        <div className="ts-item-label">{item.label}</div>
        <div
          className="ts-item-unit-description"
          dangerouslySetInnerHTML={{ __html: item.unitDescription ?? "" }}
        />
      </>
    ),
    sorter: false,
  },
  {
    title: () => (
      <FormattedMessage
        id="modalPreviewTS.QUANTITY"
        defaultMessage="QUANTITY"
      />
    ),
    key: "quantity",
    render: (item: ExtendedItem) => <Quantity item={item} />,
    sorter: false,
    width: 150,
  },
  {
    title: () => (
      <FormattedMessage
        id="modalPreviewTS.QUANTITY x VALUE"
        defaultMessage="QUANTITY x VALUE"
      />
    ),
    key: "qty_x_value",
    render: (item: ExtendedItem) => {
      if (item.price !== undefined) {
        return `€ ${Math.round((item.quantity ?? 1) * (item.price ?? 0))}`;
      } else {
        return "";
      }
    },
    sorter: false,
    width: 150,
  },
  {
    title: () => (
      <FormattedMessage
        id="modalPreviewTS.DISCOUNT"
        defaultMessage="DISCOUNT"
      />
    ),
    key: "discount",
    render: (item: ExtendedItem) =>
      item.discount ? `${(item.discount * 100).toFixed(2)} %` : "",
    sorter: false,
    width: 150,
  },
  {
    title: () => (
      <FormattedMessage
        id="modalPreviewTS.NET VALUE"
        defaultMessage="NET VALUE"
      />
    ),
    key: "net_value",
    render: (item: ExtendedItem) => {
      if (item.price !== undefined) {
        const discount = 1 - (item.discount ?? 0);
        return `€ ${Math.round(
          discount * (item.quantity ?? 1) * (item.price ?? 0)
        )}`;
      } else {
        return "";
      }
    },
    sorter: false,
    width: 150,
  },
  {
    title: () => (
      <FormattedMessage id="modalPreviewTS.VERSION" defaultMessage="VERSION" />
    ),
    key: "version",
    render: (item: ExtendedItem) => <Version item={item} />,
    sorter: false,
    width: 150,
  },
];

export const quoteColumnsModal: ColumnProps<QuoteInputExt>[] = [
  {
    title: () => (
      <FormattedMessage id="modalPreviewTS.name" defaultMessage="NAME" />
    ),
    key: "unitDescription",
    render: (_, item: QuoteInputExt) => (
      <div className={classNames({ "margin-left--15": item.isKit })}>
        <div className="ts-item-label">
          {item.label}
          {item.kitCode ? ` - ${item.kitCode}` : ""}
        </div>
        {item.description && (
          <div className="ts-item-unit-description">{item.description}</div>
        )}
      </div>
    ),
    sorter: false,
  },
  {
    title: () => (
      <FormattedMessage id="customerReference" defaultMessage="REF. CUSTOMER" />
    ),
    key: "customerReference",
    render: (item: QuoteInputExt) =>
      item.isKit ? null : (
        <CustomerReference
          item={{
            id: item.itemId,
            customerReference: item.customerReference,
            updatingCR: item.updatingCR,
            permission: item.permission,
          }}
        />
      ),
    width: 150,
  },
  {
    title: () => (
      <FormattedMessage
        id="modalPreviewTS.QUANTITY"
        defaultMessage="QUANTITY"
      />
    ),
    key: "quantity",
    render: (_, item: QuoteInputExt) =>
      item.isKit ? (
        (item.itemQuantity ?? 1) * (item.kitQuantity ?? 1)
      ) : (
        <Quantity
          item={{
            id: item.itemId,
            quantity: item.itemQuantity,
            updating: item.updating,
            permission: item.permission,
          }}
        />
      ),
    sorter: false,
    align: "right",
    width: 150,
  },
  {
    title: () => (
      <FormattedMessage
        id="modalPreviewTS.QUANTITY x VALUE"
        defaultMessage="QUANTITY x VALUE"
      />
    ),
    key: "qty_x_value",
    render: (_, item: QuoteInputExt) => {
      if (item.price !== undefined) {
        return `€ ${Math.round((item.itemQuantity ?? 1) * (item.price ?? 0))}`;
      } else {
        return "";
      }
    },
    sorter: false,
    align: "right",
    width: 150,
  },
];
