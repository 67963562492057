import { Button, Col, Icon, Input, message, Modal, Row, Table } from "antd";
import { PaginationConfig } from "antd/lib/table";
import { get } from "lodash";
import * as React from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { durations } from "../../config/defaults";
import { ITEM_NEW, PROJECT_OPEN } from "../../config/paths";
import settingsInfo from "../../config/settings";
import { Item, Portfolio, Project } from "../../generated/axios";
import { Exceptions } from "../../shared/components/exceptions";
import { messages } from "../../shared/lib/locales/definedMessages";
import pagination from "../../shared/lib/pagination";
import { exportCSVMultipleItems } from "../../shared/store/items/actions";
import { availablePortfoliosSelector } from "../../shared/store/portfolio/selectors";
import {
  getItemsTechnicalSpecificationPdf,
  getProjectDetails,
  quoteItems,
  updateProject,
} from "../../shared/store/project/actions";
import {
  ExtendedItem,
  ExtendedProject,
} from "../../shared/store/project/types";
import {
  GetTechSpecParams,
  getTechSpecSettings,
} from "../../shared/store/settings/actions";
import { getItemShares } from "../../shared/store/share/actions";
import { IStore } from "../../shared/store/types";
import {
  openModalWithForm,
  openSettingsModal,
} from "../../shared/store/ui/actions";
import {
  ModalFormMetaData,
  UIModalSettings,
} from "../../shared/store/ui/types";
import { storeProjectId } from "../../shared/store/utils/actions";
import { Any } from "../../types/Any";
import { Callback } from "../../types/Callback";
import { CapabilityCheck, capabilityMap } from "../capability";
import { filterByShowIfCondition } from "../capability/lib";
import IconLink from "../configurator/components/IconLink";
import { SETTING_FIELDS } from "../configurator/modals/SettingsModal/SettingsModal";
import PortfolioSelector from "../portfolioSelector";
import { ModalPreviewTechnicalSpecification } from "./ModalPreviewTechnicalSpecification";
import { ModalQuote } from "./ModalQuote";
import "./projectDetails.scss";
import { numericSorter, projectDetailsColumns } from "./projectDetailsColumns";

const { TextArea } = Input;

interface StateProps {
  availablePortfolios: Portfolio[];
  capabilities: string[];
  offerDiscount?: number;
  project: ExtendedProject;
  showPrices?: boolean;
}

interface DispatchProps {
  exportCSVMultipleItems: (itemIds: number[]) => void;
  getItemShares: (itemId: number) => void;
  getItemsTechnicalSpecificationPdf: (
    itemIds: number[],
    buildOffer?: boolean
  ) => void;
  getProjectDetails: (projectId: number, withMessenger: boolean) => void;
  getTechSpecSettings: (params: GetTechSpecParams) => Promise<void>;
  openModalWithForm: (formType: string, metaData: ModalFormMetaData) => void;
  openSettingsModal: (payload: Omit<UIModalSettings, "visible">) => void;
  quoteItems: (itemIds: number[], okCallback?: Callback) => void;
  storeProjectId: (id: number) => void;
  updateProject: (projectId: number, project?: Project) => void;
}

const HeadingDisplay = React.memo(() => (
  <>
    <Col span={1} offset={1}>
      <IconLink href={PROJECT_OPEN} />
    </Col>
    <Col span={22}>
      <div className="project-detail__details">
        <FormattedMessage
          id="project details"
          defaultMessage="Project details"
        />
      </div>
    </Col>
  </>
));

const ChangeSettingsLink = React.memo(
  (props: { openSettingsModal: Callback }) => (
    <a
      className="title__change-settings"
      onClick={() => props.openSettingsModal()}
    >
      <FormattedMessage
        id="view/change settings"
        defaultMessage="View/change settings"
      />
    </a>
  )
);

const ErrorEmptyNameDisplay = React.memo(() => (
  <Col span={22}>
    <span className="new-project__error">
      <FormattedMessage
        id="project title empty"
        defaultMessage="Project title can't be empty"
      />
    </span>
  </Col>
));

interface IState {
  projectTitle: string;
  inputVisibile: boolean;
  noteModalVisible: boolean;
  selectedRows: ExtendedItem[];
  selectedRowKeys: number[];
  selectedCpqRowKeys: number[];
  errorEmptyName: boolean;
  isVisibleModalPreviewTS: boolean;
  isVisibleModalQuote: boolean;
}

interface IProps
  extends StateProps,
    DispatchProps,
    InjectedIntlProps,
    RouteComponentProps<{ projectId: string }> {}

class ProjectDetails extends React.Component<IProps, IState> {
  readonly state = {
    projectTitle: "",
    inputVisibile: false,
    noteModalVisible: false,
    selectedRows: [], // Only with unitDescription
    selectedRowKeys: [], // Only with unitDescription
    selectedCpqRowKeys: [], // Only with unitDescription and cpq
    errorEmptyName: false,
    isVisibleModalPreviewTS: false,
    isVisibleModalQuote: false,
  };

  projectId: number;
  userNameInput: React.RefObject<Input>;
  paginationInfo: PaginationConfig;

  constructor(props: IProps) {
    super(props);
    this.projectId = ~~this.props.match.params.projectId; // ~~ Convert string to number
    // the reference to the project title input (initially hidden)
    this.userNameInput = React.createRef<Input>();
    this.paginationInfo = pagination();
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    const rows = props.project.items ?? [];

    const selectedRows = rows
      .filter((row) => state.selectedRowKeys.includes(row.id ?? 0))
      .filter(
        (row) =>
          row.unitDescription !== undefined && row.unitDescription !== null
      )
      .sort(numericSorter("position"));
    const selectedRowsKeys = selectedRows.map((row) => row.id ?? 0);

    const selectedCpqRowKeys = selectedRows
      .filter((row) => row.cpq)
      .map((row) => row.id ?? 0);

    return {
      selectedRows,
      selectedRowsKeys,
      selectedCpqRowKeys,
    };
  }

  componentDidMount() {
    this.props.getProjectDetails(this.projectId, false);
  }

  createButton = (action: string, label: React.ReactElement, item: Item) => (
    <Button
      size="default"
      type="default"
      onClick={() => {
        this.onItemClick(action, { item, refresh: true });
      }}
    >
      {label}
    </Button>
  );

  rowSelection: object = {
    // when checking/unchecking a row
    onChange: (rowKeys: number[]): void => {
      this.setState({ selectedRowKeys: rowKeys });
    },
  };

  openSettingsModal = () => {
    this.props.openSettingsModal({
      sections: [
        SETTING_FIELDS.UI_MEASUREMENT_SYSTEM,
        SETTING_FIELDS.RESULTS_FIELD_LIST,
        SETTING_FIELDS.TECHNICAL_SPECIFICATION_COL,
      ],
      scope: settingsInfo.scopes.PROJECT,
      title: (
        <FormattedMessage
          id="project settings"
          defaultMessage="Project settings"
        />
      ),
    });
  };

  onItemClick = (action: string, metaData: ModalFormMetaData) => {
    this.props.openModalWithForm(action, metaData);
  };

  // given the itemId, it calls '/items/{itemId}/shares'
  // then inside the action the is dispatched
  shareItem = (itemId?: number) => {
    // open the modal popup "Sharing Item"
    itemId && this.props.getItemShares(itemId);
  };

  // when input is enabled, every time a key is pressed update the state with the title
  onChangeProjectTitle = (projectTitle: string) => {
    this.setState({ projectTitle });
  };

  /** Manages to show/hide text and its input with opposite visibility:
   *  state.inputVisible == false --> title text shown, input hidden
   *  state.inputVisible == true --> title text hidden, input hidden shown
   */
  enableChangeTitle = () => {
    this.setState((prevState) => ({
      inputVisibile: !prevState.inputVisibile,
      projectTitle: this.props.project.title ?? "",
    }));
    // wait 0,1 sec and then focus the input, to advise the user has to input the new project title (without the timeout it could not work sometime)
    setTimeout(() => {
      this.userNameInput.current?.focus();
    }, 100);
  };

  noGoodSelectionMessenger = () => {
    message.error(
      <FormattedMessage
        id="message.select_at_least_one_element"
        defaultMessage="Select at least one element"
      />,
      durations.error
    );
  };

  isGoodSelection = () => {
    const { selectedRowKeys } = this.state;
    return selectedRowKeys.length > 0;
  };

  // call API to get Items Tech Spec PDF --> at lease one selected
  getTechnicalSpecifications = (buildOffer?: boolean) => {
    if (this.isGoodSelection()) {
      const { selectedRowKeys } = this.state;
      this.props.getItemsTechnicalSpecificationPdf(selectedRowKeys, buildOffer);
    } else this.noGoodSelectionMessenger();
  };

  openModalPreviewTS = async () => {
    await this.props.getTechSpecSettings({
      projectId: this.projectId,
      settingsContext: settingsInfo.scopes.PROJECT,
    });
    // Reload project's items (because could be changed when updating project settings)
    this.props.getProjectDetails(this.projectId, false);
    this.setState((state) => ({
      ...state,
      isVisibleModalPreviewTS: true,
    }));
  };

  closeModalPreviewTS = () => {
    this.setState((state) => ({ ...state, isVisibleModalPreviewTS: false }));
  };

  onOkModalPreviewTS = () => this.getTechnicalSpecifications(true);

  isGoodSelectionForQuote = () => {
    const { selectedCpqRowKeys } = this.state;
    return selectedCpqRowKeys.length > 0;
  };

  onOkModalQuote = async () => {
    const { selectedCpqRowKeys } = this.state;
    const closeModalCallback = () => {
      this.props.getProjectDetails(this.projectId, false);
      this.modalQuote(false);
    };
    this.props.quoteItems(selectedCpqRowKeys, closeModalCallback);
  };

  modalQuote = (show: boolean) => {
    this.setState((state) => ({
      ...state,
      isVisibleModalQuote: show,
    }));
  };

  // call API to get Items Tech Spec CSV --> at lease one selected
  exportCSVMultipleItems = () => {
    if (this.isGoodSelection()) {
      const { selectedRowKeys } = this.state;
      // call API `/items/results/csv`
      this.props.exportCSVMultipleItems(selectedRowKeys);
    } else this.noGoodSelectionMessenger();
  };

  showNoteModal = () => {
    this.onItemClick("noteProject", {
      projectId: this.projectId,
      refresh: true,
    });
  };

  onCancel = () => this.setState({ noteModalVisible: false });

  onAddNote = (e: React.SyntheticEvent) => {
    if (e) {
      this.setState({ noteModalVisible: false });
    }
  };

  // when input is enabled, every time a key is pressed check if it's Enter and in case call enableChangeTitle() to update the state.title
  saveNewTitle = (e: Any) => {
    // check string emptiness before send
    if (e.target && e.target.value == "") {
      e.preventDefault();
      // if error is not showing from before, show it - this will reload the component with an error message below the input
      if (!this.state.errorEmptyName) {
        this.setState({
          errorEmptyName: true,
        });
      }
      // keep the focus on the element
      this.userNameInput.current?.focus();
    } else {
      const updatedProject = {
        title: this.state.projectTitle,
      };
      // call API "/projects/{projectId}" with PUT method to update the entity, passing
      // - projectId as query parameter
      // - an object of type Project with only the title (the only part has to be updated in this context)
      // since the project in editing is connected to Redux, and it's been updated, our component in page will be re-rendered - with the new value(s) - in this case the updated title
      this.props.updateProject(this.projectId, updatedProject);
      // close the input and show the title as label (as pre-edit)
      this.setState((prevState) => ({
        inputVisibile: !prevState.inputVisibile,
        errorEmptyName: false,
      }));
    }
  };

  manageEscTabButtons = (event: Any) => {
    // case input is empty and tab is pressed: don't do anything, and keep the focus there
    if (event.target.value == "" && event.key === "Tab") {
      event.preventDefault();
      // case TAB is pressed and the input value is not empty (case intercepted above)
    } else if (event.key === "Tab") {
      // tab press
      this.saveNewTitle(event);
      // case blur on ESC press in any case (title empty or valid): restore the original value and hide the original text
    } else if (event.key === "Escape") {
      this.setState({
        projectTitle: this.props.project.title ?? "",
        inputVisibile: false,
        errorEmptyName: false,
      });
    }
  };

  requestStoreProjectId = () => {
    // store temp data, is not async
    this.props.storeProjectId(this.projectId);
  };

  onQuoteURLClick = () => {
    const url = this.props.project.cpq?.quote?.url;
    if (url) window.open(url, "_blank")?.focus();
  };

  render() {
    const { intl, openModalWithForm, offerDiscount, project, showPrices } =
      this.props;
    const buttonDisabled = !this.isGoodSelection();
    const createQuoteDisabled = !this.isGoodSelectionForQuote();

    const columns = projectDetailsColumns(
      this.createButton,
      this.shareItem,
      openModalWithForm
    );

    return (
      <div className="project-detail">
        <Row gutter={16} className="project-detail__heading gutter-bug">
          <HeadingDisplay />
        </Row>

        <Exceptions.AsRow>
          <Exceptions.Project />
        </Exceptions.AsRow>

        <Exceptions.AsRow>
          <Exceptions.Item />
        </Exceptions.AsRow>

        <Row className="title">
          <Col span={16} offset={1}>
            <Row className="title__container">
              <Col span={24}>
                {!this.state.inputVisibile && (
                  <div className="title__name">
                    {project.title}
                    <CapabilityCheck
                      showIfHasNot={capabilityMap.PROJECT_READONLY}
                    >
                      <Icon
                        type="edit"
                        className="edit-icon"
                        onClick={this.enableChangeTitle}
                      />
                    </CapabilityCheck>
                  </div>
                )}
                {this.state.inputVisibile && (
                  <Input
                    ref={this.userNameInput}
                    className="title__name"
                    onBlur={this.saveNewTitle}
                    onChange={(e) =>
                      this.onChangeProjectTitle(
                        get(e, "nativeEvent.target.value", "")
                      )
                    }
                    onKeyDown={this.manageEscTabButtons}
                    onPressEnter={this.saveNewTitle}
                    placeholder={this.state.projectTitle}
                    value={this.state.projectTitle}
                  />
                )}
                {this.state.errorEmptyName && (
                  <Row>
                    <ErrorEmptyNameDisplay />
                  </Row>
                )}
              </Col>
            </Row>
            <Row>
              <hr />
            </Row>

            <Row className="title__subtitle">
              <Col span={16}>
                {project.owner && !project.owner.isMe ? (
                  <div className="title__shared-by">
                    <FormattedMessage
                      id="sharedBy"
                      defaultMessage="Shared by"
                    />
                    : {project.owner.email}
                  </div>
                ) : null}
              </Col>
              <Col span={8}>
                {project.id && (
                  <CapabilityCheck
                    showIfHasNot={capabilityMap.PROJECT_READONLY}
                  >
                    <ChangeSettingsLink
                      openSettingsModal={this.openSettingsModal}
                    />
                  </CapabilityCheck>
                )}
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <div className="summary">
                  <Table
                    rowKey={"id"}
                    columns={filterByShowIfCondition(
                      showPrices
                        ? columns
                        : columns.filter((c) => c.key !== "price"),
                      this.props.capabilities
                    )}
                    dataSource={project.items}
                    pagination={this.paginationInfo}
                    rowSelection={this.rowSelection}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={6} className="buttons">
            <ul>
              <CapabilityCheck
                showIfHas={capabilityMap.ITEM_EXP_CSV_STANDARD_VERSION}
              >
                <li key={"summaryTable"}>
                  <Button
                    type="primary"
                    icon="bars"
                    block
                    className="buttons__button button-with-truncate-long-text"
                    disabled={buttonDisabled}
                    onClick={() => this.exportCSVMultipleItems()}
                  >
                    <FormattedMessage
                      id="summary table"
                      defaultMessage="SUMMARY TABLE"
                    />
                    <span>&nbsp;</span>
                  </Button>
                </li>
              </CapabilityCheck>
              <CapabilityCheck showIfHas={capabilityMap.ITEM_EXP_TECH_SPEC}>
                <li key="techSpec">
                  <CapabilityCheck
                    showIfHasNot={
                      capabilityMap.SETTINGS_TECH_SPEC_DOWNLOAD_FULL_OFFER
                    }
                  >
                    <Button
                      type="primary"
                      icon="printer"
                      block
                      className="buttons__button button-with-truncate-long-text"
                      disabled={buttonDisabled}
                      onClick={() => this.getTechnicalSpecifications()}
                    >
                      <FormattedMessage
                        id="technical specification"
                        defaultMessage="TECHNICAL SPECIFICATION"
                      />
                    </Button>
                    <span>&nbsp;</span>
                  </CapabilityCheck>
                  <CapabilityCheck
                    showIfHas={
                      capabilityMap.SETTINGS_TECH_SPEC_DOWNLOAD_FULL_OFFER
                    }
                  >
                    <Button
                      type="primary"
                      icon="printer"
                      block
                      className="buttons__button button-with-truncate-long-text"
                      disabled={buttonDisabled}
                      onClick={this.openModalPreviewTS}
                    >
                      <FormattedMessage
                        id="technical specification"
                        defaultMessage="TECHNICAL SPECIFICATION"
                      />
                      <span>&nbsp;</span>
                    </Button>
                  </CapabilityCheck>
                </li>
                <CapabilityCheck
                  showIfHas={
                    capabilityMap.SETTINGS_TECH_SPEC_DOWNLOAD_FULL_OFFER
                  }
                >
                  <ModalPreviewTechnicalSpecification
                    items={this.state.selectedRows}
                    offerDiscount={offerDiscount}
                    onCancel={this.closeModalPreviewTS}
                    onOk={this.onOkModalPreviewTS}
                    visible={this.state.isVisibleModalPreviewTS}
                  />
                </CapabilityCheck>
              </CapabilityCheck>

              <CapabilityCheck showIfHas={capabilityMap.ITEM_QUOTE}>
                <li key="quote">
                  <Button
                    type="primary"
                    icon="dollar"
                    block
                    className="buttons__button button-with-truncate-long-text"
                    disabled={Boolean(project.isDefault) || createQuoteDisabled}
                    onClick={() => this.modalQuote(true)}
                  >
                    {project.cpq ? (
                      <FormattedMessage
                        id="update quote"
                        defaultMessage="UPDATE QUOTE"
                      />
                    ) : (
                      <FormattedMessage
                        id="create quote"
                        defaultMessage="CREATE QUOTE"
                      />
                    )}
                    <span>&nbsp;</span>
                  </Button>
                  <ModalQuote
                    update={Boolean(project.cpq)}
                    itemIds={this.state.selectedCpqRowKeys}
                    onCancel={() => this.modalQuote(false)}
                    onOk={this.onOkModalQuote}
                    visible={this.state.isVisibleModalQuote}
                  />
                </li>

                <li key="openQuote">
                  <Button
                    type="primary"
                    icon="dollar"
                    block
                    className="buttons__button button-with-truncate-long-text"
                    disabled={!project.cpq?.quote?.url}
                    onClick={this.onQuoteURLClick}
                  >
                    <FormattedMessage
                      id="open quote"
                      defaultMessage="OPEN QUOTE"
                    />
                    <span>&nbsp;</span>
                  </Button>
                </li>
              </CapabilityCheck>

              <CapabilityCheck showIfHasNot={capabilityMap.PROJECT_READONLY}>
                <li key="addNote">
                  <Button
                    type="primary"
                    icon="file-text"
                    block
                    className="buttons__button button-with-truncate-long-text"
                    onClick={this.showNoteModal}
                  >
                    <FormattedMessage
                      id="add a note"
                      defaultMessage="ADD A NOTE TO PROJECT"
                    />
                    {/* since elements are in flex / justify:space between the icon tag must be always printed even empty type (means no icon shown but html printed), otherwise with two elements the text will be moved to the right.
                    Since empty Icon raises a warning in chrome's console, use a  span instead */}
                    {/* <Icon type="" className="right-icon" /> */}
                    <span>&nbsp;</span>
                  </Button>
                  <Modal
                    title="Add a note"
                    visible={this.state.noteModalVisible}
                    onOk={this.onAddNote}
                    onCancel={this.onCancel}
                    centered
                  >
                    {/* TODO gestire il value della text area */}
                    <TextArea
                      rows={4}
                      placeholder={intl.formatMessage(messages.textInsertNote)}
                    />
                  </Modal>
                </li>
              </CapabilityCheck>
              <li key="sharedSelected" className="display--none">
                <Button
                  type="primary"
                  icon="share-alt"
                  block
                  className="buttons__button"
                  disabled
                >
                  <FormattedMessage
                    id="shared selected"
                    defaultMessage="shared selected"
                  />
                  {/* since elements are in flex / justify:space between the icon tag must be always printed even empty type (means no icon shown but html printed), otherwise with two elements the text will be moved to the right.
                  Since empty Icon raises a warning in chrome's console, use a  span instead */}
                  {/* <Icon type="" className="right-icon" /> */}
                  <span>&nbsp;</span>
                </Button>
              </li>
            </ul>
          </Col>
        </Row>

        <Row>
          <Col span={21} offset={1}>
            <hr className="project-detail__hr" />
          </Col>
        </Row>

        <Row>
          <Col span={7} offset={1} className="section gutter-row">
            <PortfolioSelector
              availablePortfolios={this.props.availablePortfolios}
              outerClickHandler={this.requestStoreProjectId}
              linkTo={ITEM_NEW}
              label={
                <FormattedMessage
                  id="new.item.title"
                  defaultMessage="New Item"
                />
              }
              icon={
                <Icon
                  className="icon--blue"
                  type="plus-circle"
                  theme="outlined"
                />
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: IStore): StateProps => ({
  availablePortfolios: availablePortfoliosSelector(state),
  capabilities: state.capabilities.list,
  offerDiscount: state.settings.techSpecSettings?.offerDiscount,
  project: state.project as ExtendedProject,
  showPrices: state.settings.techSpecSettings?.price,
});

const mapDispatchToProps = {
  exportCSVMultipleItems,
  getItemShares,
  getItemsTechnicalSpecificationPdf,
  getProjectDetails,
  getTechSpecSettings,
  openModalWithForm,
  openSettingsModal,
  quoteItems,
  storeProjectId,
  updateProject,
};

const WithIntl = injectIntl(ProjectDetails);

export default connect(mapStateToProps, mapDispatchToProps)(WithIntl);
