import { Form, Icon, Switch, Tooltip } from "antd";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { BoundsConfig } from "../../../../../generated/axios";
import { useAppDispatch } from "../../../../../shared/store/hooks";
import {
  setBounds,
  setBoundsToDefault,
  unsetBoundsToDefault,
} from "../../../../../shared/store/settings/actions";
import {
  boundsSelector,
  defaultMetricSystemSelector,
  tmpMetricSystemSelector,
} from "../../../../../shared/store/settings/selectors";
import { useApplicationType } from "../Bounds";
import { ConvertedInputNumber } from "./ConvertedInputNumber";
import { handleVisibility } from "./handleVisibility";
import { UnitMeasure } from "./UnitMeasure";

/**
 * returns a function that updates the bounds for a given application type
 */
const useUpdateBounds = (currentApplicationType: number) => {
  const boundsSettings = useSelector(boundsSelector);
  const dispatch = useAppDispatch();
  return (property: string) => (value: number) => {
    const newBounds = {
      ...boundsSettings,
      [property]: {
        ...boundsSettings[property],
        [currentApplicationType]: value,
      },
    };
    dispatch(setBounds(newBounds));
  };
};

/**
 * Renders the form for a single application type
 */
export const ApplicationTypeBoundsForm: React.FC<{
  boundsSettings: BoundsConfig;
  currentApplicationType: number;
}> = ({ boundsSettings, currentApplicationType }) => {
  const dispatch = useAppDispatch();
  const update = useUpdateBounds(currentApplicationType);
  const applicationType = useApplicationType(currentApplicationType);

  const onChangeDefaults = useCallback(
    (checked: boolean) => {
      if (checked) dispatch(setBoundsToDefault(currentApplicationType));
      else dispatch(unsetBoundsToDefault(currentApplicationType));
    },
    [dispatch, currentApplicationType]
  );

  const ShowIfEnabled = handleVisibility(applicationType?.id);

  const metricSystem = useSelector(tmpMetricSystemSelector);
  const defaultMetricSystem = useSelector(defaultMetricSystemSelector);

  // Application type description, used in tooltip
  const description = (
    <div
      dangerouslySetInnerHTML={{
        __html: applicationType?.description ?? "",
      }}
    />
  );

  const forcedDefaults = Boolean(
    boundsSettings.forcedDefaults?.[currentApplicationType]
  );

  return (
    <div className="bounds__application-type-form" key={currentApplicationType}>
      <h4>
        {applicationType?.name}{" "}
        <Tooltip title={description} mouseEnterDelay={0.8}>
          <Icon type="info-circle" />
        </Tooltip>
      </h4>

      <Form.Item
        className="bounds__label"
        label={
          <FormattedMessage
            id="setToDefaults"
            defaultMessage="Set default values"
          />
        }
      >
        <Switch defaultChecked={forcedDefaults} onChange={onChangeDefaults} />
      </Form.Item>

      <ShowIfEnabled
        conditions={boundsSettings.minimumCapacityToleranceEnabled}
      >
        <Form.Item
          className="bounds__label"
          label={
            <UnitMeasure
              defaultMetricSystem={defaultMetricSystem}
              metricSystem={metricSystem}
              units={boundsSettings.minimumCapacityToleranceUnits}
            >
              <FormattedMessage
                id="minimumCapacityTolerance"
                defaultMessage="Min Capacity Tolerance"
              />
            </UnitMeasure>
          }
        >
          <ConvertedInputNumber
            defaultMetricSystem={defaultMetricSystem}
            defaultValue={
              boundsSettings.minimumCapacityTolerance?.[
                currentApplicationType
              ] ?? boundsSettings.minimumCapacityToleranceDefault
            }
            disabled={forcedDefaults}
            max={boundsSettings.minimumCapacityToleranceMax}
            metricSystem={metricSystem}
            min={boundsSettings.minimumCapacityToleranceMin}
            onChange={update("minimumCapacityTolerance")}
            units={boundsSettings.minimumCapacityToleranceUnits}
          />
        </Form.Item>
      </ShowIfEnabled>

      <ShowIfEnabled
        conditions={boundsSettings.maximumCapacityToleranceEnabled}
      >
        <Form.Item
          className="bounds__label"
          label={
            <UnitMeasure
              defaultMetricSystem={defaultMetricSystem}
              metricSystem={metricSystem}
              units={boundsSettings.maximumCapacityToleranceUnits}
            >
              <FormattedMessage
                id="maximumCapacityTolerance"
                defaultMessage="Max Capacity Tolerance"
              />
            </UnitMeasure>
          }
        >
          <ConvertedInputNumber
            defaultMetricSystem={defaultMetricSystem}
            defaultValue={
              boundsSettings.maximumCapacityTolerance?.[
                currentApplicationType
              ] ?? boundsSettings.maximumCapacityToleranceDefault
            }
            disabled={forcedDefaults}
            max={boundsSettings.maximumCapacityToleranceMax}
            metricSystem={metricSystem}
            min={boundsSettings.maximumCapacityToleranceMin}
            onChange={update("maximumCapacityTolerance")}
            units={boundsSettings.maximumCapacityToleranceUnits}
          />
        </Form.Item>
      </ShowIfEnabled>

      <ShowIfEnabled conditions={boundsSettings.maxResultsEnabled}>
        <Form.Item
          className="bounds__label"
          label={
            <FormattedMessage id="maxResults" defaultMessage="Max Results" />
          }
        >
          <ConvertedInputNumber
            defaultMetricSystem={defaultMetricSystem}
            defaultValue={
              boundsSettings.maxResults?.[currentApplicationType] ??
              boundsSettings.maxResultsDefault
            }
            disabled={forcedDefaults}
            max={boundsSettings.maxResultsMax}
            metricSystem={metricSystem}
            min={boundsSettings.maxResultsMin}
            onChange={update("maxResults")}
          />
        </Form.Item>
      </ShowIfEnabled>

      <ShowIfEnabled conditions={boundsSettings.soundLevelToleranceEnabled}>
        <Form.Item
          className="bounds__label"
          label={
            <UnitMeasure
              defaultMetricSystem={defaultMetricSystem}
              metricSystem={metricSystem}
              units={boundsSettings.soundLevelToleranceUnits}
            >
              <FormattedMessage
                id="soundLevelTolerance"
                defaultMessage="Sound Level Tolerance"
              />
            </UnitMeasure>
          }
        >
          <ConvertedInputNumber
            defaultMetricSystem={defaultMetricSystem}
            defaultValue={
              boundsSettings.soundLevelTolerance?.[currentApplicationType] ??
              boundsSettings.soundLevelToleranceDefault
            }
            disabled={forcedDefaults}
            max={boundsSettings.soundLevelToleranceMax}
            metricSystem={metricSystem}
            min={boundsSettings.soundLevelToleranceMin}
            onChange={update("soundLevelTolerance")}
            units={boundsSettings.soundLevelToleranceUnits}
          />
        </Form.Item>
      </ShowIfEnabled>

      <ShowIfEnabled conditions={boundsSettings.minFluidPressureDropEnabled}>
        <Form.Item
          className="bounds__label"
          label={
            <UnitMeasure
              defaultMetricSystem={defaultMetricSystem}
              metricSystem={metricSystem}
              units={boundsSettings.minFluidPressureDropUnits}
            >
              <FormattedMessage
                id="minFluidPressureDrop"
                defaultMessage="Min Fluid Pressure Drop"
              />
            </UnitMeasure>
          }
        >
          <ConvertedInputNumber
            defaultMetricSystem={defaultMetricSystem}
            defaultValue={
              boundsSettings.minFluidPressureDrop?.[currentApplicationType] ??
              boundsSettings.minFluidPressureDropDefault
            }
            disabled={forcedDefaults}
            max={boundsSettings.minFluidPressureDropMax}
            metricSystem={metricSystem}
            min={boundsSettings.minFluidPressureDropMin}
            onChange={update("minFluidPressureDrop")}
            units={boundsSettings.minFluidPressureDropUnits}
          />
        </Form.Item>
      </ShowIfEnabled>

      <ShowIfEnabled conditions={boundsSettings.maxFluidPressureDropEnabled}>
        <Form.Item
          className="bounds__label"
          label={
            <UnitMeasure
              defaultMetricSystem={defaultMetricSystem}
              metricSystem={metricSystem}
              units={boundsSettings.maxFluidPressureDropUnits}
            >
              <FormattedMessage
                id="maxFluidPressureDrop"
                defaultMessage="Max Fluid Pressure Drop"
              />
            </UnitMeasure>
          }
        >
          <ConvertedInputNumber
            defaultMetricSystem={defaultMetricSystem}
            defaultValue={
              boundsSettings.maxFluidPressureDrop?.[currentApplicationType] ??
              boundsSettings.maxFluidPressureDropDefault
            }
            disabled={forcedDefaults}
            max={boundsSettings.maxFluidPressureDropMax}
            metricSystem={metricSystem}
            min={
              (boundsSettings.minFluidPressureDrop?.[currentApplicationType] ??
                boundsSettings.minFluidPressureDropDefault ??
                0) + 50
            }
            onChange={update("maxFluidPressureDrop")}
            units={boundsSettings.maxFluidPressureDropUnits}
          />
        </Form.Item>
      </ShowIfEnabled>

      <ShowIfEnabled
        conditions={boundsSettings.condensingTemperatureToleranceEnabled}
      >
        <Form.Item
          className="bounds__label"
          label={
            <UnitMeasure
              defaultMetricSystem={defaultMetricSystem}
              metricSystem={metricSystem}
              units={boundsSettings.condensingTemperatureToleranceUnits}
            >
              <FormattedMessage
                id="condensingTemperatureTolerance"
                defaultMessage="Condensing Temperature Tolerance"
              />
            </UnitMeasure>
          }
        >
          <ConvertedInputNumber
            defaultMetricSystem={defaultMetricSystem}
            defaultValue={
              boundsSettings.condensingTemperatureTolerance?.[
                currentApplicationType
              ] ?? boundsSettings.condensingTemperatureToleranceDefault
            }
            disabled={forcedDefaults}
            max={boundsSettings.condensingTemperatureToleranceMax}
            metricSystem={metricSystem}
            min={boundsSettings.condensingTemperatureToleranceMin}
            onChange={update("condensingTemperatureTolerance")}
            units={boundsSettings.condensingTemperatureToleranceUnits}
          />
        </Form.Item>
      </ShowIfEnabled>

      <ShowIfEnabled
        conditions={boundsSettings.evaporatingTemperatureToleranceEnabled}
      >
        <Form.Item
          className="bounds__label"
          label={
            <UnitMeasure
              defaultMetricSystem={defaultMetricSystem}
              metricSystem={metricSystem}
              units={boundsSettings.evaporatingTemperatureToleranceUnits}
            >
              <FormattedMessage
                id="evaporatingTemperatureTolerance"
                defaultMessage="Evaporating Temperature Tolerance"
              />
            </UnitMeasure>
          }
        >
          <ConvertedInputNumber
            defaultMetricSystem={defaultMetricSystem}
            defaultValue={
              boundsSettings.evaporatingTemperatureTolerance?.[
                currentApplicationType
              ] ?? boundsSettings.evaporatingTemperatureToleranceDefault
            }
            disabled={forcedDefaults}
            max={boundsSettings.evaporatingTemperatureToleranceMax}
            metricSystem={metricSystem}
            min={boundsSettings.evaporatingTemperatureToleranceMin}
            onChange={update("evaporatingTemperatureTolerance")}
            units={boundsSettings.evaporatingTemperatureToleranceUnits}
          />
        </Form.Item>
      </ShowIfEnabled>
    </div>
  );
};
